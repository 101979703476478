
// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: $red;
// $font-size-base: .875rem;



// The color of the SVG loader should be modified manually.
// See assets/svg/components/circle-preloader.svg
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v29-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/open-sans-v29-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v29-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v29-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v29-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v29-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v29-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}


/* orbitron-regular - latin */
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/orbitron-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/orbitron-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/orbitron-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/orbitron-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/orbitron-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/orbitron-v25-latin-regular.svg#Orbitron') format('svg'); /* Legacy iOS */
}
/* orbitron-700 - latin */
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/orbitron-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/orbitron-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/orbitron-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/orbitron-v25-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/orbitron-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/orbitron-v25-latin-700.svg#Orbitron') format('svg'); /* Legacy iOS */
}


$primary:                                                   #ED6A3B;
$font-family-base:                                          "Open Sans", sans-serif;

// Colors
$body-color:                                                #000000!important;

// Avatar sizes

$avatar-width-xxl :                                         10rem;
$avatar-height-xxl:                                         10rem;


h2 { color: $primary}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {

font-family: 'Orbitron';
font-style: normal;
font-weight: 700;

}


.bg-black {

background-color:  #000000!important;

}